import axios from 'axios';

const sign = require('jwt-encode');
const secret = 'hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y';
const data = {
  name: 'Plysovnik',
};
const jwt = sign(data, secret); 

let URL 
let API_ROUTE
const STAGE = process.env.NODE_ENV
 
if(STAGE==='development') {
  URL = 'http://localhost/plysovnik/';
 // URL = 'http://localhost/plysovnik/';
//  API_ROUTE = 'http://localhost:8001';
  API_ROUTE = 'https://api.plysovnik.cz/';
  // URL = 'https://admin.plysovnik.cz/public/';
} else {
  URL = 'https://test.plysovnik.cz/admin/'; 
  API_ROUTE = 'https://api.plysovnik.cz/';
}

const axiosInstance = axios.create({
  // withCredentials: true,
  baseURL: URL + 'public/',
  headers: {
    'Content-Type': 'application/json',
    "Authorization" : `Bearer ` + jwt
  }
});


const protectedAxios = () => {
  const userToken = JSON.parse(localStorage.getItem('userData'));

  return axios.create({
      // withCredentials: true,
      baseURL: URL + 'api/',
      headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ` + userToken?.accessToken
      }
  });
}

export const axiosProtected = protectedAxios; 
export const CONFIG_APP_API_ROUTE = API_ROUTE;

export default axiosInstance;